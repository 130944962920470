import React, { useEffect, useState } from 'react';
import Container from '../helper/Container';
import styles from './Css/Industries.module.css';
import axios from 'axios';
import Env from '../util/Env';
import { getAsset } from '../util/helper';
import { useNavigate } from 'react-router-dom';

const Card = ({ title, image, id , navigate}) => (
  <div className={styles.card} onClick={() => navigate(`/openindustry/${title.replace(/\s+/g, '-')}`)}>
    <img src={getAsset(image)} alt={title} className={styles.cardImage} />
    <h3 className={styles.cardTitle}>{title}</h3>
    <button className={styles.getQuoteButton}>Get a Quote</button>
  </div>
);

function Industries() {
  const navigate = useNavigate();
  const [allIndustries, setAllIndustries] = useState([]);

  useEffect(() => {
    GetAllIndustries();
  }, []);

  const GetAllIndustries = () => {
    axios
      .get(`${Env.server}/api/industry/getAll`)
      .then((res) => {
        setAllIndustries(res.data.industries);
      })
      .catch((err) => {
        console.log('abc----', err);
      });
  };

  return (
    <Container>
      <div className={styles.header}>
        <h1 className={styles.title} >Industries:</h1>
        <button className={styles.seeAllButton} onClick={() => navigate("/allindustries")}>See All</button>
      </div>
      <div className={styles.cardContainer}>
        {allIndustries.map((industry, index) => (
          <Card key={index} title={industry.title} image={industry?.image[0]} id={industry._id} navigate={navigate} />
        ))}
      </div>
    </Container>
  );
}



export default Industries;
