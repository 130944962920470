// Chatbot.js
import { useEffect } from 'react';

const Chatbot = () => {
  useEffect(() => {
    var s1 = document.createElement('script');
    var s0 = document.getElementsByTagName('script')[0];

    s1.async = true;
    s1.src = 'https://embed.tawk.to/6706399ecec6d0125df3b58a/1i9o525an';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    return () => {
      s0.parentNode.removeChild(s1);
    };
  }, []);

  return null;
};

export default Chatbot;