import React from 'react'
import style from './CSS/OpenQuote.module.css'
import QuoteForm from '../Components/QuoteForm'
import Container from '../helper/Container'

function OpenQuote() {
  return (
    <Container>
      <div className={style.container}>
        <div className={style.imageContainer}>
          <img src="https://ik.imagekit.io/tdyftai86/abc.png?updatedAt=1730684637867" alt="Quote Background" className={style.image} />
          <p className={style.imageText}>Discover our tailored packaging solutions that bring your brand to life.</p>
        </div>
        <div className={style.formContainer}>
          <QuoteForm />
          <p className={style.formText}>Please fill out the form, and our team will get back to you with a personalized quote.</p>
        </div>
      </div>
    </Container>
  )
}

export default OpenQuote
