import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CSS/ViewAllIProducts.module.css';
import Container from '../helper/Container';
import Env from '../util/Env';
import { getAsset } from '../util/helper';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function ViewAllProducts() {
  const navigate = useNavigate();
    const [GetProduct, setGetProd] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state

    useEffect(() => {
        getBlogs();
    },[])

    
  const [homeseo, setHomeseo] = useState({});
  useEffect(() => {
    getAllData()
 }, []);

 const getAllData = () => {
     axios
     .get(`${Env.server}/api/homeseo/getall`)
     .then((res) => {
         console.log("Abc====", res.data.homeseo)
         let resp = res.data.homeseo
         setHomeseo(resp.data.homeseo)
     })
     .catch((err) => {
         console.log(err);
     });
 }


    const getBlogs = () => {
        axios.get(`${Env.server}/api/product/getAllProduct`)
            .then((resp) => {
                let res = resp.data.products;
                console.log("res", res);
                setGetProd(res);
                setLoading(false); // Data fetched, set loading to false
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false); // Error occurred, set loading to false
            })
    }

    return (
    <>
       <Helmet>
                <title>{homeseo.title}</title>
                <meta name="description" content={homeseo.description} />
                <meta name="keywords" content={homeseo.keywords} />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={homeseo.ogTitle} />
                <meta property="og:description" content={homeseo.ogDescription} />
                <meta property="og:image" content={homeseo.ogImage} />
                <meta property="og:url" content={homeseo.ogUrl} />
            </Helmet>



    <div className={styles.headerContainer}>
      <Container>
        <h1 className={styles.mainHeading}>Blockbuster Custom Printed Boxes</h1>
        <h2 className={styles.subHeading}>Go Well For Every Product</h2>
        <p className={styles.description}>
          We operate on the principle of prioritizing customers over industries. You are special to us, so whatever products you are promoting, we enhance their beauty with meticulously crafted boxes. Industry-wide design gallery or product display is a way to assist you in finding your right corner. Choose by industry and make the perfect choice you deserve.
        </p>
      </Container>
    </div>

    {/* Image Section */}
    <div className={styles.imageSection}>
      <Container>
        <h2 className={styles.categoryHeading}>Customize Products</h2>
        <div className={styles.imageGrid}>
          {loading ? (
            <p>Loading Products...</p>
          ) : GetProduct.length > 0 ? (
            GetProduct.map((product) => (
              <div key={product._id} className={styles.imageCard}>
                <img
                  src={getAsset(product.image[0])}
                  alt={product.title} // Adjust based on your API response field names
                  className={styles.image}
                />
                <p>{product.title}</p>
                <button className={styles.customizeButton} onClick={()=> navigate(`/openproduct/${product.title.replace(/\s+/g, '-')}`)}>Customize ➔</button>
              </div>
            ))
          ) : (
            <p>No products available</p>
          )}
        </div>
      </Container>
    </div>
  </>
  )
}

export default ViewAllProducts
