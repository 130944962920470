import React, { useEffect, useState } from "react";
import Styles from "./CSS/OpenIndustry.module.css";
import { useParams } from "react-router-dom";
import Container from "../helper/Container";
import axios from "axios";
import Env from "../util/Env";
import { getAsset } from "../util/helper";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// Simulate fetching data
const fetchData = async (id) => {
  return axios
    .get(`${Env.server}/api/industry/getOne/${id}`)
    .then((response) => response.data.industries)
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const fetchProducts = async (industryId) => {
  console.log("abc======", industryId)
  return axios
    .get(`${Env.server}/api/product/getSingleByIndustryID/${industryId}`)
    .then((response) => {
      console.log("muzzamal-======", response.data.products);
      return response.data.products;
    })
    .catch((error) => {
      console.error("Error fetching products:", error);
    });
};



function OpenIndustry() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [industry, setIndustry] = useState({
    title: "Loading...",
    description: "Loading...",
    image: [],
  });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const industryData = await fetchData(id.replace(/-/g, ' '));
      setIndustry(industryData);
    };
    loadData();
  }, [id]);

  useEffect(() => {
    const loadProducts = async () => {
      console.log("ididididid=-====",industry.industry)
      const productData = await fetchProducts(industry._id);
      setProducts(productData); // If no products, set dummy products
      setLoading(false);
    };
    loadProducts();
  }, [industry.industry]);

  return (
    <>
     <Helmet>
        <title>{industry?.title}</title>
        <meta name="description" content={industry?.mataDescription} />
        <meta name="keywords" content={industry?.FocusKeyWords} />
        <meta property="og:title" content={industry?.title} />
        <meta property="og:description" content={industry?.mataDescription} />
        <meta property="og:image" content={getAsset(industry?.image[0])} />
        <link rel="canonical" href="https://pentagonpackaging.com/" />
      </Helmet>


      <Container>
        <div className={Styles.headingContainer}>
          <div>
            <div className={Styles.imageSection}>
              <img
                src={getAsset(industry?.image[0])}
                alt="Big"
                className={Styles.bigImage}
              />
            </div>
            <h1 className={Styles.heading}>{industry?.title}</h1>
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: industry?.description }}
            />
          </div>

          {/* Product Section */}
          <div className={Styles.productSection}>
            <h1 className={Styles.productHeading}>Our Products</h1>
            <div className={Styles.imageGrid}>
              {loading ? (
                <p>Loading Products...</p>
              ) : products?.length > 0 ? (
                products?.slice(0, 10).map((product) => (
                  <div key={product._id} className={Styles.imageCard}>
                    <img
                      src={getAsset(product.image[0])}
                      alt={product.title}
                      className={Styles.image}
                    />
                    <p>{product.title}</p>
                    <button className={Styles.customizeButton} onClick={() => navigate(`/openproduct/${product.title.replace(/\s+/g, '-')}`)}>
                      Customize ➔
                    </button>
                  </div>
                ))
              ): (
                <p>No Products Found</p>
              )}
            </div>
            {(
              <div className={Styles.seeMoreContainer}>
                <button className={Styles.seeMoreButton} onClick={()=> navigate("/allproducts")}>See More</button>
              </div>
            )}
          </div>
        </div>

      </Container>

      <div className={Styles.Scrollercontainer}>
        <Container>
          <div className={Styles.scrollableDescription}>
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: industry?.description2 }}
            />
          </div>
        </Container>
      </div>


    

    </>
  );
}

export default OpenIndustry;
