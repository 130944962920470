import React, { useState, useEffect } from 'react';
import style from "./Css/ContentSection.module.css";
import Container from '../helper/Container';
import axios from 'axios';
import Env from '../util/Env';

function ContentSection() {
  const [getData, setGetData] = useState({});

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    axios
      .get(`${Env.server}/api/contentsection/`)
      .then((res) => {
        console.log("Fetched Data: ", res.data);
        setGetData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container>
      <div className={style.contentWrapper}>
        {/* Center the title */}
        {/* Content box with left-aligned content */}
        <div
          className={style.contentBox}
        >
        <h1 className={style.title}>{getData.title}</h1>
          <div
            className={style.content}
            dangerouslySetInnerHTML={{ __html: getData.content }}
          ></div>
        </div>
      </div>
      <br/>
    </Container>
  );
}

export default ContentSection;
