import React,{useEffect, useState} from 'react';
import Container from '../helper/Container';
import styles from './Css/Howtoorder.module.css';
import axios from 'axios';
import Env from '../util/Env';
import { getAsset } from '../util/helper';



function Howtoorder() {
    const [howToOrder, setHowToOrder] = useState("");
    const [howToOrderHead, setHowToOrderHead] = useState("");
    const [howToOrderImage, setHowToOrderImage] = useState([]);

    useEffect(() => {
        getAllHeadContent();
    }, []);

    const getAllHeadContent = () => {
        axios.get(`${Env.server}/api/homepage/getAll`)
            .then((res) => {
                let da = res.data.hoempage;
                setHowToOrder(da.howToOrder);
                setHowToOrderHead(da.orderHead);
                setHowToOrderImage(da.imageHowToOrder)
            })
            .catch((err) => {
                console.log("err----", err);
            })
    }
  return (
    <div style={{ backgroundColor: "#f0faff", paddingTop: "20px", paddingBottom: "40px" }}>
      <Container>
        <h1 style={{ textAlign: "center", marginBottom: "30px", color: "#1b80ad" }}>{howToOrderHead}</h1>
        <div className={styles.howToOrderContainer}>
          <div className={styles.leftSection}>
            <div className={styles.imageGrid}>
            {howToOrderImage.slice(0,4).map((image, index) => (
              <img src={getAsset(image)} key={index} alt="pentagonPackaging" className={styles.image} />
                            ))}
             </div>
          </div>
          <div className={styles.rightSection}>
          {/* html code is in the howToOrder */}
            <p dangerouslySetInnerHTML={{ __html: howToOrder }} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Howtoorder;