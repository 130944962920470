import React, { useState } from 'react';
import style from "./CSS/Contactus.module.css";
import Container from '../helper/Container';
import { Helmet } from 'react-helmet';

function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    console.log('Form Data:', formData); // Console log the form data
    

  };

  return (
    <>
     <Helmet>
        <title>Home Page - My Website</title>
        <meta name="description" content="Welcome to the homepage of My Website. Explore our services and products." />
        <meta name="keywords" content="Home, My Website, Services, Products" />
        <meta property="og:title" content="Home Page - My Website" />
        <meta property="og:description" content="Welcome to the homepage of My Website. Explore our services and products." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <link rel="canonical" href="https://example.com/" />
      </Helmet>



      <Container>
        <div className='formContactUs'>
          <div className={style.contactContainer}>
            <h1>Contact Us</h1>
            <form onSubmit={handleSubmit} className={style.contactForm}>
              <div className={style.formGroup}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Your Name"
                  className={style.inputField}
                />
              </div>

              <div className={style.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Your Email"
                  className={style.inputField}
                />
              </div>

              <div className={style.formGroup}>
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  placeholder="Subject"
                  className={style.inputField}
                />
              </div>

              <div className={style.formGroup}>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Your Message"
                  className={style.textAreaField}
                />
              </div>

              <button type="submit" className={style.submitButton}>
                Send Message
              </button>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Contactus;
