import React, {useState, useEffect } from 'react'
import HeaderSlider from '../Components/HeaderSlider'
import AllServices from '../Components/AllServices'
import Industries from '../Components/Industries'
import Howtoorder from '../Components/Howtoorder'
import Product from '../Components/Product'
import MapQuote from '../Components/MapQuote'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import Env from '../util/Env'
import ContentSection from '../Components/ContentSection'

function Home() {

  const [homeseo, setHomeseo] = useState({});
  useEffect(() => {
    getAllData()
 }, []);

 const getAllData = () => {
     axios
     .get(`${Env.server}/api/homeseo/getall`)
     .then((res) => {
         console.log("Abc====", res.data.homeseo)
         let resp = res.data.homeseo
         setHomeseo(resp.data.homeseo)
     })
     .catch((err) => {
         console.log(err);
     });
 }



  return (
    <>
   <Helmet>
                <title>{homeseo.title}</title>
                <meta name="description" content={homeseo.description} />
                <meta name="keywords" content={homeseo.keywords} />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={homeseo.ogTitle} />
                <meta property="og:description" content={homeseo.ogDescription} />
                <meta property="og:image" content={homeseo.ogImage} />
                <meta property="og:url" content={homeseo.ogUrl} />
            </Helmet>
   
        <HeaderSlider/>
        <AllServices/>
        <Industries/>
        <Howtoorder/>
        <Product/>
        <MapQuote/>
       <ContentSection/>

        

    </>
  )
}

export default Home