import React from 'react';
import Container from '../helper/Container';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Importing Leaflet's CSS for proper map styling
import styles from './Css/MapQuote.module.css';
import QuoteForm from './QuoteForm'; // Import the new form component

function MapQuote() {
  const mapCenter = [30.167102, -81.752920]; // Center of the map

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.wrapper}>
          
          {/* Map Section */}
          <div className={styles.mapContainer}>
            <div className={styles.mapBox}>
              <MapContainer center={mapCenter} zoom={12} style={{ height: "100%", width: "100%" }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={mapCenter}>
                  <Popup>
                    This is the specified location.
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>

          {/* Get Quote Form Section */}
          <div className={styles.formContainer}>
            <QuoteForm />
          </div>
          
        </div>
      </Container>
    </div>
  );
}

export default MapQuote;
