import React, { useState } from "react";
import styles from "./Css/QuotForm.module.css";
import Env from "../util/Env";
import { useDropzone } from "react-dropzone";
import axios from "axios";

function QuoteForm() {
  // Define state for each form field
  const [productInfo, setProductInfo] = useState("");
  const [imageUpload, setImageUpload] = useState(null); // for image file
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [selectField, setSelectField] = useState("");
  const [selectField2, setSelectField2] = useState("");
  const [selectField3, setSelectField3] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [image, setImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendingForm, setSendingForm] = useState(false);

  const colorOptions = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
  ];

  const [fileName, setFileName] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setFileName(file.name); // Update the file name state
    }
  };

  // image upload
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const publishImage = () => {
    if (number === "" || email === "") {
      alert("Please enter email and phone number");
    }

    console.log("abc===", image);
    if (imagesFront.length > 0) {
      console.log("imagesFront", imagesFront);
      console.log("image", image);
      const formData = new FormData();
      formData.append("imagefront", imagesFront);

      image &&
        image.map((img) => {
          formData.append(`images`, img);
        });

      console.log("formData", formData);

      console.log("image", formData);
      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
          //   setResponseImages(objectNames);
          if (res.data.message === "imagesSaved") {
            console.log("image", objectNames);
            publishBlogNow(objectNames);
          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    } else {
      setLoading(true);

      const formData = {
        productInfo,
        width: field1,
        length: field2,
        units: selectField,
        color: selectField2,
        stock: selectField3,
        height: field4,
        depth: field3,
        name,
        email,
        phNum: number,
        message: additionalInfo,
      };

      console.log("formData", formData);

      axios
        .post(`${Env.server}/api/quoteform/PostQuoteInformation`, formData)
        .then((response) => {
          console.log("response", response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  const publishBlogNow = (objectNames) => {
    console.log("data-----", objectNames);
    setLoading(true);

    const formData = {
      productInfo,
      images: objectNames,
      width: field1,
      length: field2,
      units: selectField,
      color: selectField2,
      stock: selectField3,
      height: field4,
      depth: field3,
      name,
      email,
      phNum: number,
      message: additionalInfo,
    };

    axios
      .post(`${Env.server}/api/quoteform/PostQuoteInformation`, formData)
      .then((response) => {
        console.log("response", response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div className={styles.formContainer}>
        <h2>Get Quote</h2>
        {/* Product Information */}
        <div className={styles.formField}>
          <label>Product Information</label>
          <input
            type="text"
            name="productInfo"
            className={styles.inputField}
            placeholder="Enter product details"
            value={productInfo}
            onChange={(e) => setProductInfo(e.target.value)}
          />
        </div>

        {/* Image Uploader */}
        <div className="form-group text-center">
          <div
            onClick={handleInsideClick}
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              width: "140px",
              height: "50px",
              border: "1px dashed #aaa",
              cursor: "pointer",
              position: "relative",
              borderRadius: "5px",
              overflow: "hidden",
              backgroundColor: "black",
            }}
          >
            <h3
              style={{ color: "white", marginTop: "12px", marginLeft: "5px" }}
            >
              Upload Design
            </h3>
            <input
              {...getInputProps()}
              id="fileInput" // Ensure this ID is present
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            />
          </div>
          {imagesFront.length > 0 && (
            <div>
              <p style={{ fontSize: "10px" }}>
                {/* for one or more image */}
                {imagesFront[0].name}
              </p>
            </div>
          )}
        </div>

        {/* 3 Input Fields and 1 Select Field */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input
            type="text"
            name="field1"
            className={styles.inputField}
            placeholder="Width"
            value={field1}
            onChange={(e) => setField1(e.target.value)}
          />
          <input
            type="text"
            name="field2"
            className={styles.inputField}
            placeholder="Length"
            value={field2}
            onChange={(e) => setField2(e.target.value)}
          />

          <input
            type="text"
            name="field2"
            className={styles.inputField}
            placeholder="Height"
            value={field4}
            onChange={(e) => setField4(e.target.value)}
          />

          <select
            name="selectField"
            className={styles.selectField}
            value={selectField}
            onChange={(e) => setSelectField(e.target.value)}
          >
            <option value="" disabled>
              Select Units
            </option>
            <option value="inch">inch</option>
            <option value="cm">cm</option>
            <option value="mm">mm</option>
          </select>
        </div>

        {/* 2 Select Fields and 1 Input Field */}
        <div className={`${styles.formField} ${styles.row}`}>
        <input
    type="color"
    name="colorPicker"
    className={styles.colorPicker}
    value={selectField2}  // Assuming selectField2 holds the color value
    onChange={(e) => setSelectField2(e.target.value)}
    title="Pick a color"
  />
          <input
            type="number"
            name="selectField3"
            className={styles.selectField}
            value={selectField3}
            onChange={(e) => setSelectField3(e.target.value)}
            placeholder="Enter Stock Quantity"
          />

          <input
            type="text"
            name="field3"
            className={styles.inputField}
            placeholder="Stock"
            value={field3}
            onChange={(e) => setField3(e.target.value)}
          />
        </div>

        {/* Name, Email, Number */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input
            type="text"
            name="name"
            className={styles.inputField}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            className={styles.inputField}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="tel"
            name="number"
            className={styles.inputField}
            placeholder="Phone Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>

        {/* Textbox */}
        <div className={styles.formField}>
          <label>Additional Information</label>
          <textarea
            name="additionalInfo"
            className={styles.textareaField}
            placeholder="Enter any additional information"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          ></textarea>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          onClick={() => {
            setLoading(true);
            publishImage();
          }}
          className={styles.submitButton}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default QuoteForm;
