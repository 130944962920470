import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAsset } from '../util/helper';
import styles from './Css/HeaderSlider.module.css';
import Env from '../util/Env';
import axios from 'axios';

function HeaderSlider() {
  const [allGetHeaderImg, setAllGetHeaderImg] = useState([]);

  useEffect(() => {
    GetAllImgHeader();
  }, []);

  const GetAllImgHeader = () => {
    axios
      .get(`${Env.server}/api/header/headerImgGet`)
      .then((res) => {
        setAllGetHeaderImg(res.data.header.img);
      })
      .catch((err) => {
        console.log('abc----', err);
      });
  };

  const settings = {
    dots: true,
    infinite: allGetHeaderImg.length > 1, // Disable infinite loop for a single image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: allGetHeaderImg.length > 1, // Autoplay only if more than one image
    autoplaySpeed: 3000,
    arrows: allGetHeaderImg.length > 1, // Hide arrows if only one image
  };

  return (
    <>
      <div className={styles.landingPage}>
        <Slider {...settings}>
          {allGetHeaderImg.map((image, index) => (
            <div key={index} className={styles.imageContainer}>
              <img
                src={getAsset(image)}
                alt={`Slide ${index + 1}`}
                className={styles.overlayImage}
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Responsive Marquee Section */}
      <div className={styles.marqueeContainer}>
        <marquee direction="left" behavior="scroll" scrollamount="7">
          <span className={styles.marqueeText}>
            <b>"Hurry Up! </b>
            <b>20% discount</b> to our new clients."
          </span>
        </marquee>
      </div>
    </>
  );
}

export default HeaderSlider;
