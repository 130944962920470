import React, { useEffect } from 'react'
import Container from '../helper/Container'
import Styles from './Css/Faqs.module.css'
import FaqQA from './FaqQA'

function Faqs() {
  return (
    <>
              <section className={Styles.faqSection}>
    <Container>
        <div className={Styles.innerSection}>
            <h2 className={Styles.faqTitle}>Frequently Asked Questions</h2>
            <h1 className={Styles.mainTitle}>
                A Single Platform To Manage Your{' '}
                <span className={Styles.highlight}>End-To-End Packaging Workflow</span>
            </h1>
            <p className={Styles.descrip}>
                Below you'll find answers to some common questions around creating a custom box. 
                Every order is a little different though, so don't hesitate to reach out with anything 
                else you might be wondering.
            </p>
        </div>
    </Container>
</section>

<FaqQA/>

    </>
  )
}

export default Faqs