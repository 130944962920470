import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Env from "../util/Env";
import styles from "./Css/FaqQA.module.css"; // Importing the module CSS


const FaqQA = () => {
  const [faqs , setFaqs] = useState([]);
  const [heading , setHeading] = useState("");
  const [description , setDescription] = useState("");

  useEffect(() => {
    getAllData();
  },[])

  const getAllData = () => {
    axios
      .get(`${Env.server}/api/faq/GetAll`)
      .then((res) => {
        console.log("abc=====", res.data.faqs[0]);
        setFaqs(res.data.faqs[0].QA);
        setHeading(res.data.faqs[0].heading);
        setDescription(res.data.faqs[0].description);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };





    
      // State to track which FAQ is open
      const [openIndex, setOpenIndex] = useState(null);
      const contentRef = useRef([]);
    
      // Handle toggle of each FAQ
      const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
    
      return (
        <div className={styles.container}>
          {/* Heading Section */}
          <div className={styles.header}>
            <h2>Your Queries, Our Expertise</h2>
            <h3>{heading}</h3>
            <p>{description}</p>
          </div>
    
          {/* FAQ Section */}
          <div className={styles.faqGrid}>
            {/* Left Column */}
            <div className={styles.faqColumn}>
              {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
                <div key={index} className={styles.faqItem}>
                  <div
                    className={styles.question}
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                  </div>
                  <div
                    ref={(el) => (contentRef.current[index] = el)}
                    className={`
                      ${styles.answer}
                      ${openIndex === index ? styles.open : styles.closed}
                    `}
                    style={{
                      maxHeight: openIndex === index ? `${contentRef.current[index].scrollHeight}px` : "0",
                    }}
                  >
                    {faq.answer}
                  </div>
                </div>
              ))}
            </div>
    
            {/* Right Column */}
            <div className={styles.faqColumn}>
              {faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
                <div key={index + faqs.length / 2} className={styles.faqItem}>
                  <div
                    className={styles.question}
                    onClick={() => toggleFAQ(index + faqs.length / 2)}
                  >
                    {faq.question}
                  </div>
                  <div
                    ref={(el) => (contentRef.current[index + faqs.length / 2] = el)}
                    className={`
                      ${styles.answer}
                      ${openIndex === index + faqs.length / 2 ? styles.open : styles.closed}
                    `}
                    style={{
                      maxHeight: openIndex === index + faqs.length / 2 ? `${contentRef.current[index + faqs.length / 2].scrollHeight}px` : "0",
                    }}
                  >
                    {faq.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

    
export default FaqQA;
