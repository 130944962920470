// src/components/AllServices.js
import React from 'react';
import styles from "./Css/AllServices.module.css";
import img1 from "../assets/images/AllDetails/satisfaction.png";
import img2 from "../assets/images/AllDetails/budgeting.png";
import img4 from "../assets/images/AllDetails/delivery-man.png";
import img5 from "../assets/images/AllDetails/premium.png";
import img6 from "../assets/images/AllDetails/achievement.png";
import Container from '../helper/Container';

const serviceTypes = [
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" },
    { img: img1, title: "Best Quality" },
    { img: img2, title: "Budget Friendly" },
    { img: img4, title: "Delivery" },
    { img: img5, title: "Premium Packaging" },
    { img: img6, title: "Safe" }
    
];

function AllServices() {
    return (
        <div style={{ backgroundColor: "#f0faff", padding: "40px" }}>

        <Container>
        <div className={styles.container}>
            <h1 className={styles.title}>Our Service Type</h1>
            <div className={styles.scrollableContainer}>
                <div className={styles.scrollContent}>
                    {/* Duplicate the content for seamless looping */}
                    {[...serviceTypes, ...serviceTypes].map((service, index) => (
                        <div key={index} className={styles.boxes}>
                            <img
                                src={service.img}
                                alt={service.title}
                                className={styles.image}
                            />
                            {/* <div className={styles.title}>{service.title}</div> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </Container>
        </div>
    );
}

export default AllServices;
