import React, { useEffect, useState } from "react";
import Styles from "./CSS/OpenProducts.module.css"; // Using CSS module for styling
import Container from "../helper/Container";
import { useParams } from "react-router-dom";
import QuoteForm from "../Components/QuoteForm";
import Specification from "../Components/Specification";
import axios from "axios";
import Env from "../util/Env";
import { getAsset } from "../util/helper";
import Faqs from "../Components/Faqs";
import{Helmet} from "react-helmet";
function OpenProducts() {
  const { id } = useParams();

  // Dummy images
  const [images, setimages] = useState([])
  const [product, setProduct] = useState([])

  // Hook to manage the large image
  const [largeImage, setLargeImage] = useState("");
  const [faqType, setFaqType] = useState("");

  useEffect(() => {
    const loadData = async () => {
      await fetchProducts(id.replace(/-/g, ' '));
    };

    loadData();
  }, []);

  const fetchProducts = async (id) => {
    console.log("taibamuzzamal======",id)
    axios.get(`${Env.server}/api/product/getSingleProduct/${id}`)
    .then((res) => {
      console.log("abc======",res.data.products);
      setLargeImage(res.data.products.image[0]);
      setimages(res.data.products.image);
      setProduct(res.data.products)

      if(res.data.products.industry && res.data.products.shapeStyle){
        setFaqType("both")
      }else if(res.data.products.industry){
        setFaqType("industry")
      }else if(res.data.products.shapeStyle){
        setFaqType("shape")
      }else{
        setFaqType("none")
      }


      
    })
    .catch((err)=>{
      console.log("err=====",err)
    })
  };

  // Handle click on small images
  const handleImageClick = (image) => {
    setLargeImage(image);
  };

  return (
    <>
       <Helmet>
        <title>{product?.title}</title>
        <meta name="description" content={product?.mataDescription} />
        <meta name="keywords" content={product?.FocusKeyWords} />
        <meta property="og:title" content={product?.title} />
        <meta property="og:description" content={product?.mataDescription} />
        <meta property="og:image" content={getAsset(product?.image)} />
        <link rel="canonical" href="https://pentagonpackaging.com/" />
      </Helmet>
      <Container>
        {/* Heading Section */}
        <h1 className={Styles.heading}>{product && product.title}</h1>
        <p 
  className={Styles.description} 
  dangerouslySetInnerHTML={{ __html: product?.description || "No description available" }} 
/>





{/* section 2 */}
        <div className={Styles.content}>
          <div className={Styles.leftSide}>
            <img src={getAsset(largeImage)} alt="Large Product" className={Styles.largeImage} />
            <div className={Styles.smallImages}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={getAsset(image)}
                  alt={`Small Image ${index + 1}`}
                  className={Styles.smallImage}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
            <div className={Styles.features}>
              <p><span className={Styles.tick}>&#10003;</span> Low Minimums of 100 Boxes</p>
              <p><span className={Styles.tick}>&#10003;</span> Free Die and Plates</p>
              <p><span className={Styles.tick}>&#10003;</span> Competitive Pricing</p>
              <p><span className={Styles.tick}>&#10003;</span> Free Design Support</p>
            </div>
            <br/>
          </div>
          
          <div className={Styles.rightSide}>
            <QuoteForm/>
          </div>
        </div>

{product ? (
  <>
        <Specification/>
        <br/>
        <br/>
  </>
):null}



        {/* Section three */}

        <div className={Styles.Scrollercontainer}>
        <Container>
          <div className={Styles.scrollableDescription}>
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: product.description2 }}
            />

          </div>
        </Container>
        
      </div>
      <br/>
      </Container>

{/* Section 4 */}

  <Faqs/>



    </>
  );
}

export default OpenProducts;
